<template>
    <LiefengContent :isBack='true' @backClick="backClick">
        <!-- 社区动员组织管理导入并同步 -->
        <template v-slot:title>导入并同步
            <!-- <Button type="error" v-if="$route.query.isLineHome " @click="$router.push(`/mobilizationindex?isLineHome=${$route.query.isLineHome}`)">返回</Button>
            <Button type="error" v-if="$route.query.isLineHome && $route.query.isback" @click="$router.push(`/mobilizationsystem?isLineHome=${$route.query.isLineHome}`)">返回</Button> -->
            
        </template>
        <!-- <template v-slot:title>社区动员组织导入待确认</template> -->
        <template v-slot:toolsbarRight>
           <!-- <Tooltip placement="right-start"  style="width:200px;margin-right: 10px;">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="searchData.communityCode"
                  filterable
                  @on-change="getSearchTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip> -->
            <Form  :label-colon="true" :inline="true" class="search">
            <FormItem>
            <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.name"
                placeholder="按姓名查找"
                style="margin-right:10px;width: 150px"
                @on-enter="getList"
            />
            </FormItem>
            <FormItem>
             <Select transfer
                placeholder="数据状态"
                v-model="searchData.status" style="width:200px;margin-right: 10px;"
            >
                <Option :value='statusAll'>全部</Option>
                <Option value="1">未同步</Option>
                <Option value="2">已同步</Option>
            </Select>
            </FormItem>
             <Button style="margin: 0 4px" type="primary" icon="ios-search" @click="searchBtn">查询</Button>
             <Button style="margin: 0 4px" type="success" icon="ios-refresh" @click="restBtn">重置</Button>
             <Button style="margin: 0 4px" type="primary" @click="importExcel" icon="ios-add-circle-outline">导入</Button>
             <Button style="margin: 0 4px" type="error" @click="deleteAll">批量删除({{selectNum}})</Button>
             <Button style="margin: 0 4px" type="primary" @click="openAll">批量同步({{selectNum}})</Button>
            </Form>
              
        </template>
         <template v-slot:contentArea>
             <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                ref="baseList"
            ></LiefengTable>
            <!-- 导入excel弹窗 -->
        <LiefengModal title="导入" width="40%" height="200px" :value="excelStatus" @input="excelModalData" class="export-modal">
            <template v-slot:contentarea>
                <!-- <Form :label-width="100">
                <FormItem>
                <span slot="label" class="validate">请选择社区:</span>
                <Tooltip placement="right-start"  style="width:100%">
                    <div slot="content" style="width: 200px;white-space: normal">
                    由于社区太多，请输入关键字进行刷选
                    </div>
                    <Select
                    v-model="nodeId"
                    filterable
                    @on-change="getTree"
                    placeholder="点击列表/输入搜索"
                    :remote-method="loadingCommunityFn"
                    :loading="loadingCommunity"
                    >
                    防止报错，使用了index 
                    <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                    </Select>
                </Tooltip>
                </FormItem>
            </Form> -->
            <LiefengUploadExcel style="margin:20px 0" :showDownModal="{isShow:true,downLink:'./excelfiles/mobilization.xlsx',downName:'社区动员组织管理导入模板'}" :action="exportAction" :uploadData="uploadData" :isShow='showExcel' @success="excelSuccess"></LiefengUploadExcel>
            </template>
        </LiefengModal>
        <LiefengModal title=" "  width="700px" :value="enterStatus" @input="changeEnterStatus" >
            <template v-slot:contentarea>
                <p style="font-weight:700">当前导入数据</p>
                <LiefengTable
                    :talbeColumns="nowColumns"
                    :tableData="nowTableData"
                    :hidePage='true'
                ></LiefengTable>
                <br>
                <p><span style="font-weight:700">选择需要替换的数据</span>将导入的数据替换下面的数据</p>
                <LiefengTable
                    :talbeColumns="artificialColumns"
                    :tableData="artificialData"
                    :loading="artificialLoading"
                    :hidePage='true'
                ></LiefengTable>
            </template>
        </LiefengModal>
        </template>
       
    </LiefengContent>
    
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengUploadExcel from './children/LiefengUploadExcel.vue'
export default {
    components:{LiefengContent,LiefengTable,LiefengModal,LiefengUploadExcel},
    data(){
        return{
            statusAll:0,
            talbeColumns: [
                {
                    width:80,
                    align:'center',
                    type:'selection'
                },
                {
                    title: "区",
                    key: "zoneName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "街道",
                    key: "streetName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "communityName",
                    width: 180,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "name",
                    width: 100,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    width: 100,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},params.row.sex == 0 ?'未知':params.row.sex == 1 ?'男':params.row.sex == 2 ?'女':'未知')
                    }
                },
                {
                    title: "手机号码",
                    key: "mobile",
                    width: 130,
                    align: "center",
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    width: 180,
                    align: "center",
                },
                {
                    title: "出生日期",
                    key: "birthday",
                    align: "center",
                    width: 120,
                    render:(h,params) =>{
                        return h('div',{},params.row.birthday ? this.$core.formatDate(new Date(params.row.birthday),"yyyy-MM-dd")  :'')
                    }
                },
                {
                    title: "现居地址",
                    key: "addr",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "所属类型",
                        key: "userType",
                        width: 180,
                        align: "center",
                        render:(h,params) =>{
                        return h(
                            'div',{}, params.row.userType ? this.identityType.map(e => {
                                if(params.row.userType == e.dictKey){
                                    return e.dictValue
                                }
                            }):''
                        )
                    }
                },
                {
                    title: "所属职位",
                        key: "userType",
                        width: 180,
                        align: "center",
                        render:(h,params) =>{
                        return h(
                            'div',{}, params.row.postName
                        )
                    }
                },
                {
                    title: "状态",
                    key: "status",
                    width: 130,
                    align: "center",
                    render:(h,params) =>{
                        return h('div',{},params.row.status ? (params.row.status == '1'?'未同步':params.row.status == '2' ?'已同步':'') :'')
                    }
                },
                {
                    title: "结果",
                    key: "resultContent",
                    width: 130,
                    align: "center"
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 200,
                    fixed:'right',
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small",
                            },
                            style: {
                                marginLeft: "14px",
                            },
                            on: {
                                click: () => {
                                    this.deleteJson(params.row.id)
                                },
                            },
                            },
                            "删除"
                        ),
                        h(
                            "Button",
                            {
                            props: {
                                type: "primary",
                                size: "small",
                            },
                            style: {
                                marginLeft: "14px",
                                display: params.row.resultCode == 2?'inline-block':'none',
                            },
                            on: {
                                click: () => {
                                    this.artificial(params.row)
                                },
                            },
                            },
                            "人工确认"
                        ),

                        ]);
                    },
                },
            ],
            tableData:[],
            loading:false,
            page:1,
            total:0,
            pageSize:20,
            currentPage:1,
            // 搜索参数
            searchData:{
                name:'',
                mobile:'',
                status:'1',
                // communityCode:''
            },
            syncLoading:false, //批量同步按钮
            operationModal:false,
            operationTip:false,
            editRow:{},
          
            excelStatus:false,
            uploadData:{
                orgCode:'',
                 oemCode:parent.vue.oemInfo.oemCode,
            },
            exportAction:'',
            headers: {openId: window.sessionStorage.getItem('openId'),Authorization:window.sessionStorage.getItem('accessToken')},
            showExcel:0,
            nodeList: [],
            //异步加载社区
            loadingCommunity: false,
            nodeId:'',
            allNodeList:[],
            tableSelectList:[],
            loading1: false,
             //社区
             allCommunityList: [],
            firstCode:'',
            nationList:[],
            selectNum:0,
            //身份类型
            identityType:[],

            enterStatus:false,
            artificialColumns:[
                {
                    title: "姓名",
                    key: "username",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "手机号码",
                    key: "mobile",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    width: 150,
                    fixed:'right',
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "primary",
                                size: "small",
                            },
                            style: {
                                marginLeft: "14px",
                            },
                            on: {
                                click: () => {
                                    this.enter(params.row.custGlobalId)
                                },
                            },
                            },
                            "替换本条"
                        ),

                        ]);
                    },
                },
            ],
            artificialData:[],
            artificialId:'',

            nowColumns:[
                {
                    title: "姓名",
                    key: "username",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "手机号码",
                    key: "mobile",
                    minWidth: 180,
                    align: "center",
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    minWidth: 200,
                    align: "center",
                },
            ],
            nowTableData:[],
        }
    },
    async created(){
        await this.getDicType('USER_IMP_TYPE','identityType')
       await this.getDictType()
       await this.getSelect()
       await this.getList()
       
    },
    methods:{
        backClick(){
            if( this.$route.query.isLineHome && this.$route.query.isback ){
                this.$router.push(`/mobilizationsystem?isLineHome=${this.$route.query.isLineHome}`)
            }else {
                this.$router.push(`/mobilizationindex?isLineHome=${this.$route.query.isLineHome}`)
            }
        },
        // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        // 点击批量同步按钮
        openAll(){
            if(this.tableSelectList && this.tableSelectList.length == 0){
                this.$Message.warning({
                    content:'请先选择数据',
                    background:true
                })
                return
            }
            this.syncList(this.tableSelectList.join(','))
        },
        // 点击删除全部按钮
        deleteAll(){
            if(this.tableSelectList && this.tableSelectList.length == 0){
                this.$Message.warning({
                    content:'请先选择数据',
                    background:true
                })
                return
            }
            this.deleteJson(this.tableSelectList.join(','))
        },
        // 返回按钮方法
       navigateBack() {
         this.$router.push('/mobilizationindex');
       },
        // 方法部分
        // 导入成功回调事件
        excelSuccess(){
          this.excelStatus = false
          this.page = this.currentPage
          this.getList()
        },
        //修改excel导入弹窗状态
        excelModalData(status) {
            if(!status && this.operationTip){
                this.excelStatus = status;
                this.operationTip = false
            }else{
                this.excelStatus = status;
            }
            

        },
        importExcel(){
            this.nodeId = ''
            this.uploadData.orgCode = this.$route.query.communityCode
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction =  window.vue.getProxy()["/syaa"].target + "/upload/sy/user/imp/importUserImp";
            },
        // 关闭模态框
        operationStatus(status){
            this.operationModal = status
        },
        // 选择了表格的tableSelect事件
        tableSelect(val){
            this.selectNum = val.length
            this.tableSelectList = []
            if(val.length != 0){
                val.map(item =>{
                    this.tableSelectList.push(item.id)
                })
            }
        },
         // 搜索按钮
        searchBtn(){
            this.page = 1
            this.getList()
        },
        // 重置按钮
        restBtn(){
            this.searchData = {
                name:'',
                mobile:'',
                status:'',
                // communityCode:''
            }
            this.getList()
        },
        // 改变分页
        hadlePageSize(val){
            this.page  = val.page
            this.pageSize = val.pageSize
            this.getList()
        },

        // 接口部分
        async getList(){
            this.loading = true
           await this.$get('/syaa/pc/sy/user/communityStructure/selectUserImpPage',{
                page:this.page,
                pageSize:this.pageSize,
                orgId:this.$route.query.orgId,
                name:this.searchData.name,
                mobile:this.searchData.mobile,
                status:this.searchData.status?this.searchData.status:'',
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                staffOrgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res=>{
                  this.loading = false
                if(res.code == 200){
                    if(res.dataList && res.dataList.length != 0){
                        res.dataList.map(item =>{
                            if(item.syncStatus == 3){
                                item._disabled = true
                            }
                            if(item.status == 2){
                                item._disabled = true
                            }
                            this.nationList.map(items =>{
                                if(item.resultCode == items.value){
                                    item.resultContent = items.label
                                }
                            })
                        })
                    }
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                    this.tableSelectList = []
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 删除全部数据，按社区
        deleteJson(ids){
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否删除该数据?",
                onOk: () => {
                    this.$post('/syaa/pc/sy/user/communityStructure/deleteUserImp',{
                        ids
                    }).then(res=>{
                        if(res.code == 200){
                        this.$Message.success({
                            content:'删除成功',
                            background:true
                        })
                        this.page = this.currentPage
                        this.getList(this.CascaderId)
                        }else{
                        this.$Message.error({
                            content:res.desc,
                            background:true
                        })
                        return
                        }
                    })
                }
            });
           
        },

         //异步加载社区
    loadingCommunityFn(query) {
       if(query !== '' && !Number(query)) {
        this.loadingCommunity = true;
       this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
       if(this.nodeList.length > 50) this.nodeList.length = 50;
        this.loadingCommunity = false;
      } else if(Number(query)) {
        return;
      }
      else {
        this.nodeList = [];
      }
    },
    async getSelect(){
      await this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
          staffId: parent.vue.loginInfo.userinfo.id,
          orgCode:parent.vue.loginInfo.userinfo.orgCode
        })
          .then((res) => {
            if (res.code === "200"&&res.dataList&&res.dataList.length>0) {
              // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
              this.nodeList = res.dataList.map((item) => {
                return {
                  value: item.code,
                  label: item.fullName,
                  projectCode: item.projectCode
                };
              });
              this.allNodeList = JSON.parse(JSON.stringify(this.nodeList));
              if(this.nodeList.length > 50) {
                this.nodeList.length = 50;
              }
            //   this.searchData.communityCode = this.nodeList[0].value
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getTree(val){
        this.nodeId = val
        this.uploadData.orgCode = this.nodeId
    },
    getSearchTree(val){
        if(val != '' && val != undefined){
            this.searchData.communityCode = val
            this.getList()
        }
        
     },
     async getDictType(){
        await this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType: "USER_IMP_ERROR"
            }).then(res => {
            if(res.code == 200) {
                this.nationList = res.dataList.map(item => {
                    return {
                        value: item.dictKey,
                        label: item.dictValue
                    }
                });
            }else {
                this.$Message.error({
                background: true,
                content: '获取民族信息失败'
                })
            }
            });
     },
    //  同步接口
    syncList(ids){
        this.$Modal.confirm({
            title: "温馨提示",
            content: "是否同步该数据?",
            onOk: () => {
                this.$post('/syaa/pc/sy/user/communityStructure/syncUserList',{
            ids
        }).then(res=>{
            if(res.code == 200){
                this.$Message.success({
                    content:`同步成功${res.data}条`,
                    background:true
                })
                this.page = 1
                this.pageSize = 20
                this.getList()
            }else{
                this.$Message.error({
                    content:'同步失败',
                    background:true
                })
                return
            }
        })
            }
        });

        
    },
    changeEnterStatus(val){
        this.enterStatus = val
    },
    artificial(data){
        let arr = [{
            username:data.name,
            mobile:data.mobile,
            idNum:data.idNum,
        }]
        this.nowTableData = arr
        this.artificialId = data.id
        this.artificialLoading = true
        this.enterStatus = true
        this.$get('/syaa/pc/sy/user/communityStructure/selectUserImpHasInfo',{
            id:data.id
        }).then( res => {
            this.artificialLoading = false
            if(res.code == 200){
                this.artificialData = res.dataList
            }else{
                this.$Message.error({
                    content:'数据获取失败',
                    background:true
                })
            }
        })
    },
    enter(custGlobalId){
        this.$post('/syaa/pc/sy/user/communityStructure/syncUserBusiUserInfo',{
            id:this.artificialId,
            custGlobalId,
        }).then( res => {
            if(res.code == 200){
                this.$Message.success({
                    content:'操作成功',
                    background:true
                })
                this.enterStatus = false
                this.getList()
            }else{
                this.$Message.error({
                    content:res.desc,
                    background:true
                })
            }
        })
    }
    }
    
}
</script>

<style lang="less" scoped>
 //编写css
   /deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
  }
  .export-modal{
  /deep/ #modal_contentarea{
        overflow: visible !important;
        height: 270px;
    }
}
</style>